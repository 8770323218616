import { createApp } from 'vue';
import router from './router';
import store from './store';
import App from './views/App.vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import './assets/css/element.scss'

const app = createApp(App);

app.use(store).use(router).use(ElementPlus, {
  locale: zhCn,
});

app.mount('#app');
