<template>
  <div class="home">
    <el-container>
      <el-header>
        <span class="brand">风鸣技术栈</span>
      </el-header>

      <el-main>
        <ul class="nav">
          <li class="nav-item" @click="handleItemClick('我的博客')">
            <i class="fm-icon-blog"></i> 我的博客
          </li>
          <li class="nav-item" @click="handleItemClick('在线工具')">
            <i class="fm-icon-tool"></i> 在线工具
          </li>
          <li class="nav-item" @click="handleItemClick('投资理财')">
            <i class="fm-icon-trade"></i> 投资理财
          </li>
        </ul>
      </el-main>

      <el-footer>
        <p class="row">&copy;2021 - 2022 By 风鸣</p>
        <p class="row">
          <a class="link" href="https://beian.miit.gov.cn/" target="_blank"
            >粤ICP备2021171667号</a
          >
          <a
            class="link"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44142302000043"
            target="_blank"
          >
            <img class="icon" src="~@/assets/images/beian.png" />
            粤公网安备44142302000043号</a
          >
        </p>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  methods: {
    handleItemClick(key) {
      const links = {
        我的博客: 'https://blog.fm-code.com/',
        在线工具: 'https://tool.fm-code.com/',
        投资理财: 'https://trade.fm-code.com/'
      };
      window.location.href = links[key];
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-width: 310px;
  background: {
    image: url('~@/assets/images/background.jpg');
    position: center center;
    repeat: no-repeat;
    size: cover;
  }

  .el-container {
    font-size: 16px;
    height: 100vh;
    width: 100vw;

    .el-header {
      height: 80px;
      line-height: 80px;

      .brand {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
    }

    .el-main {
      position: relative;

      .nav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .nav-item {
          width: 200px;
          line-height: 45px;
          text-align: center;
          cursor: pointer;
          color: #fff;
          font-size: 18px;
          background-color: #13c2c2;
          border-radius: 5px;
          transition: all 0.2s;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &:hover {
            transform: scale(1.1);
            border: 2px #fff solid;

            i {
              animation: rotate 2s infinite linear;
            }
          }

          i[class^='fm-icon'] {
            display: inline-block;
            margin-right: 5px;
            font-size: 18px;
          }
        }
      }
    }

    .el-footer {
      height: auto;
      text-align: center;
      padding: 20px 40px;

      .row {
        line-height: 30px;
        color: #fff;

        // 备案链接
        .link {
          display: inline-block;
          text-decoration: none;
          color: #fff;
          padding: 0 5px;

          // 备案图标
          .icon {
            vertical-align: text-bottom;
          }
        }
      }
    }
  }

  // 屏幕小于 400px 样式
  @media screen and (max-width: 400px) {
    .el-container {
      font-size: 14px;

      .el-header .brand {
        font-size: 18px;
      }

      .el-main .nav > .nav-item {
        width: 170px;
        line-height: 40px;
        font-size: 16px;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        i[class^='fm-icon'] {
          font-size: 16px;
        }
      }
    }
  }
}

// 旋转动画
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
